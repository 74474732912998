<script>
/* eslint-disable */
import axios from 'axios';


export default {
  data() {
    return {
      username: '',
      password: '',
      message: ''
    };
  },
  methods: {
    async registerUser() {
      try {

         const response = await axios.post('https://api.wicovsky.de/api/auth/register', {
          username: this.username,
          password: this.password
        });

        this.message = 'Erfolgreich registriert!';
        console.log(response.data);
      } catch (error) {
        this.message = 'Fehler bei der Registrierung';    
        console.error(error);

      }
    }
  }
};
</script>

<template>
  <div>
    <h2>Registrieren</h2>
    <input v-model="username" placeholder="Benutzername" />
    <input v-model="password" type="password" placeholder="Passwort" />
    <button @click="registerUser">Registrieren</button>
    <p>{{ message }}</p>
  </div>
</template>

<style scoped>
input {
  display: block;
  margin-bottom: 10px;
}
</style>
