import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '../views/RegisterLogin.vue'

const routes = [
  { path: '/', redirect: '/login' }, // Startseite leitet direkt zum Login weiter
  { path: '/login', component: Login }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
